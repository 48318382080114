import React, { Component } from 'react';
import './Header.css';

class Header extends Component {
  render() {
    return (
		<section className="blog-header">
		  <h1 className="page-title">Blog</h1>
		</section>
    );
  }
}

export default Header;
